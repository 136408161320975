<template>
  <div class="m-collected-product sf-collected-product">
    <div class="sf-collected-product__image-wrapper">
      <!-- Product image -->
      <SfLink
        v-if="link"
        :link="link"
        class="sf-collected-product__image-link"
      >
        <SfImage
          :src="image"
          :alt="title"
          :width="imageWidth"
          :height="imageHeight"
          class="sf-collected-product__image"
          @click="$emit('click:link')"
        />
      </SfLink>
      <SfImage
        v-else
        :src="image"
        :alt="title"
        :width="imageWidth"
        :height="imageHeight"
        class="sf-collected-product__image"
      />
      <SfCircleIcon
        v-if="!isRemovedFromCart"
        icon="cross"
        aria-label="Remove"
        class="sf-collected-product__remove"
        :disabled="disabledUpdates || isPending"
        @click="$emit('click:remove')"
      />
    </div>
    <div class="sf-collected-product__details">
      <!-- Product title -->
      <div class="sf-collected-product__title" :class="{ 'is-disabled': isPending }">
        <SfLink v-if="link" :link="link" @click="$emit('click:link')">
          <span @click="$emit('click:link')">{{ title }}</span>
        </SfLink>
        <template v-else>
          <span @click="$emit('click:link')">{{ title }}</span>
        </template>
      </div>

      <!-- Product configuration / options -->
      <div class="sf-collected-product__configuration">
        <slot name="configuration" />
      </div>

      <!-- Product price and quantity -->
      <div class="sf-collected-product__bottom">
        <div class="sf-collected-product__price" :class="{ 'is-disabled': isPending }">
          <SfPrice
            v-if="regularPrice"
            :regular="regularPrice"
            :special="regularPrice !== specialPrice ? specialPrice : null"
          />
        </div>
        <div class="sf-collected-product__inputs">
          <div
            v-if="isRemovedFromCart"
            @click="handleUndoButton"
            class="m-collected-product__bring-back sf-link"
            :class="{ 'is-disabled': disabledUpdates || isPending }"
            aria-label="Revert"
          >
            {{ $t('Undo') }}
          </div>
          <AQuantitySelector
            v-else
            :qty="qty"
            :disabled="disabledUpdates || isPending"
            :disabled-decrease="qty < 1"
            :minQty="0"
            aria-label="Quantity"
            class="sf-collected-product__quantity-selector"
            @input="$emit('input', $event)"
            @click:remove="$emit('click:remove')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SfPrice from '@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfCircleIcon from '@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import AQuantitySelector from 'theme/components/atoms/a-quantity-selector';

export default {
  name: 'MCollectedProduct',
  components: {
    SfImage,
    SfCircleIcon,
    SfLink,
    SfPrice,
    AQuantitySelector
  },
  model: {
    prop: 'qty'
  },
  props: {
    image: {
      type: [String, Object],
      default: ''
    },
    imageWidth: {
      type: [String, Number],
      default: 100
    },
    imageHeight: {
      type: [String, Number],
      default: 100
    },
    title: {
      type: String,
      default: ''
    },
    regularPrice: {
      type: [Number, String],
      default: null
    },
    specialPrice: {
      type: [Number, String],
      default: null
    },
    qty: {
      type: [Number, String],
      default: 1
    },
    link: {
      type: [String, Object],
      default: ''
    },
    hasQuantitySelector: {
      type: Boolean,
      default: true
    },
    disabledUpdates: {
      type: Boolean,
      default: false
    },
    isPending: {
      type: Boolean,
      default: false
    },
    isRemovedFromCart: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleUndoButton () {
      if (this.disabledUpdates || this.isPending) return
      this.$emit('click:remove')
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfCollectedProduct.scss";
.m-collected-product {
  &__bring-back {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &.is-disabled {
      color: var(--c-text-disabled);
    }
  }
}
.sf-collected-product {
  --collected-product-image-background: var(--c-white);
  --collected-product-configuration-display: flex;
  --cp-actions-opacity: 1;
  --collected-product-remove-opacity: 1;
  --collected-product-remove-bottom: calc(var(--spacer-base) * 1.3);
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: var(--spacer-xs) 0;
  @include for-desktop {
    --price-special-font-size: var(--font-size--sm);
    --property-value-font-size: var(--font-size--sm);
    --property-name-font-size: var(--font-size--sm);
    padding: var(--spacer-sm) 0;
  }
  &:hover {
    --collected-product-background: transparent;
    --collected-product-image-background: transparent;
    --collected-product-configuration-display: initial;
    &::after { box-shadow: none; }
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--c-light-variant);
  }

  &__image-wrapper {
    margin-right: var(--spacer-xs);
    width: 88px;
    height: 96px;
  }
  &__image {
    background: var(--c-light-variant);
  }
  &__remove {
    --icon-color: var(--c-primary);
    --button-background: transparent;
    --button-box-shadow: none;
    --button-size: var(--spacer-base);
    --icon-size: var(--spacer-sm);
    display: block;
    padding-left: var(--spacer-2xs);
    position: absolute;
    top: var(--spacer-xs);
    left: 0;
    @include for-desktop {
      top: var(--spacer-sm);
    }
    &:hover { --button-background: transparent; --button-box-shadow-opacity: 0; }
    &[disabled] { visibility: hidden; }
  }

  &__configuration {
    margin: 0;
    padding: 0 0 var(--spacer-xs);
  }

  &__details {
    width: 77%;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    margin: 0;
    text-transform: uppercase;
    font-family: var(--font-family--primary);
    font-size: var(--font-size--xs);
    line-height: var(--font-size--sm);
    @include for-desktop {
      font-size: var(--font-size--sm);
      line-height: var(--font-size--base);
    }
    &.is-disabled {
      color: var(--c-text-disabled);
    }
    .sf-link {
      --link-font-family: var(--font-family--primary);
      --link-font-weight: var(--font-weight--normal);
      color: inherit;
    }
  }
  &__price {
    --price-flex-direction: column-reverse;
    --price-align-items: start;
    --price-special-margin: 0 var(--spacer-xs) 0 0;
    --price-special-font-size: var(--font-size--lg);
    --price-old-font-size: var(--font-size--sm);
    --price-old-margin: 0;
    --price-old-color: var(--c-gray);
    &.is-disabled {
      --price-regular-color: var(--c-text-disabled);
      --price-old-color: var(--c-text-disabled);
      --price-special-color: var(--c-text-disabled);
    }
    .sf-price__old {
      line-height: .75;
    }
  }
  &__inputs {
    --quantity-selector-height: auto;
    --input-height: 1.5rem;
    @include for-desktop {
      --input-height: 1.75rem;
    }
  }
  &__quantity-selector {
    --quantity-selector-background: transparent;
    border: 1px solid var(--c-light-variant);
    border-radius: 3px;
    [disabled] {
      --c-text-disabled: var(--c-light);
      --button-background: transparent;
    }
    --input-width: 2rem;
    @media only screen and (min-width: 420px) {
      --input-width: 3.5rem;
    }
    width: auto;
    height: 1.75rem;
  }
}
</style>
