<template>
  <SfSidebar
    :visible="isMicrocartOpen && isMicrocartVisible"
    :title="$t('Cart')"
    class="sf-sidebar--right sf-sidebar--icon o-microcart-panel"
    @close="$store.commit('ui/setMicrocart')"
  >
    <transition name="fade" mode="out-in">
      <div v-if="totalItems" key="my-cart" class="my-cart">
        <MCollectedProductsGroup
          :products="productsToBeListed"
          @click:remove="removeProductHandler"
          :disabled-updates="isCartBusy"
          :additional-options="removedItems"
          @click:link="closeMicrocartExtend"
        />
      </div>
      <div v-else-if="!isCartBusy" key="empty-cart" class="empty-cart">
        <div class="empty-cart__banner">
          <SfImage
            :src="'/assets/empty_cart.svg'"
            :alt="$t('Your bag is empty')"
            class="empty-cart__image"
          />
          <SfHeading
            :title="$t('Your bag is empty')"
            :description="$t('Looks like your basket is empty. Start shopping to fill it in.')"
            :level="2"
            class="empty-cart__heading"
          />
        </div>
      </div>
    </transition>
    <template #content-bottom>
      <transition name="fade">
        <div v-if="productsInCart.length" class="content-bottom">
          <div class="free-shipping">
            {{ getFreeShippingText }}
          </div>
          <SfCheckbox
            v-model="accepts"
            v-if="isActivateForStore"
            name="accepts"
            :label="$t('Jeg accepterer handelsbetingelserne')"
            class="form__element form__checkbox"
          />
          <AButtonDivided
            class="sf-button--full-width color-primary"
            :label1="$t('Checkout')"
            :label2="grandTotal | price"
            @click="goToCheckout"
            :is-disabled="isCartBusy"
          />
          <div class="o-microcart-panel__additional-pruchase-info">
            <AIcon
              class="o-microcart-panel__order-info-icon o-microcart-panel__order-info-delivery-icon"
              size="xs"
              icon="delivery"
              color="grey"
            />
            <span class="desktop-only"> {{ productLabel || $t(defaultLabel) }} </span>
            <span class="smartphone-only"> {{ productLabel || $t(defaultLabel) }} </span>
          </div>
          <div v-show="isCartBusy" class="content-bottom__spinner">
            <ASpinner :size="40" />
          </div>
          <lazy-hydrate when-visible>
            <div v-if="belowButtonBlock !== ''" class="minicart__below_button_block" v-html="belowButtonBlock" />
          </lazy-hydrate>
          <m-product-carousel
            v-if="isUpsellBlockEnabled && upsellProducts.length"
            :products="upsellProducts"
            id="upsell-slider"
            class="minicart-upsell-products"
          />
        </div>
        <div v-else>
          <SfButton
            class="sf-button--full-width color-primary"
            @click="startShopping"
          >
            {{ $t("Start shopping") }}
          </SfButton>
        </div>
      </transition>
    </template>
  </SfSidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import MCollectedProductsGroup from '~/theme/components/molecules/m-collected-products-group';
import MProductCarousel from '~/theme/components/molecules/m-product-carousel';
import AButtonDivided from '~/theme/components/atoms/a-button-divided';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfCheckbox from '@storefront-ui/vue/src/components/molecules/SfCheckbox/SfCheckbox.vue';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfSidebar from '@storefront-ui/vue/src/components/organisms/SfSidebar/SfSidebar.vue';
import ASpinner from '~/theme/components/atoms/a-spinner';
import AIcon from '~/theme/components/atoms/a-icon';
import { createProductIdentifier } from '~/theme/helpers/cart'
import LazyHydrate from 'vue-lazy-hydration';

import uniqBy from 'lodash-es/uniqBy'
import Vue from 'vue'
import config from 'config';
import i18n from '@vue-storefront/i18n';

export default {
  components: {
    AButtonDivided,
    SfButton,
    SfCheckbox,
    SfImage,
    SfHeading,
    SfSidebar,
    MCollectedProductsGroup,
    MProductCarousel,
    ASpinner,
    AIcon,
    LazyHydrate
  },
  mixins: [VueOfflineMixin, onEscapePress],
  data () {
    return {
      accepts: false,
      isMicrocartVisible: false,
      removedItems: {},
      removedProductsData: {},
      productsOrder: [],
      productsToBeListed: [],
      upsellProducts: []
    };
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart,
      deliveryDate: state => state.ui.deliveryDate
    }),
    ...mapGetters({
      totals: 'cart/getTotals',
      productsInCart: 'cart/getCartItems',
      isCartBusy: 'cart/getCartProcessingStatus',
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    defaultLabel () {
      return config.deliveryLabels.defaultLabel
    },
    grandTotal () {
      const grandTotal = this.totals.find(_ => _.code === 'grand_total')
      return grandTotal ? grandTotal.value : ''
    },
    totalItems () {
      return this.productsToBeListed.length;
    },
    productsCount () {
      let count = 0;
      this.productsInCart.forEach(product => {
        count = count + parseInt(product.qty);
      });
      return count;
    },
    removedProducts () {
      return Object.values(this.removedProductsData)
    },
    productLabel () {
      return this.deliveryDate?.desktop?.productLabel || ''
    },
    productLabelMobile () {
      return this.deliveryDate?.mobile?.productLabel || ''
    },
    belowButtonBlock () {
      const block = this.cmsBlocks.find((_) => _.identifier === 'minicart_below_button_block') || {}
      return block.content || ''
    },
    isUpsellBlockEnabled () {
      return config.minicart.enableUpsell
    },
    isActivateForStore () {
      return config.defaultStoreCode === 'dk'
    },
    getFreeShippingText () {
      let text = '';
      switch (this.productsCount) {
        case 0:
          text = i18n.t('Text in cart with 0 items')
          break;
        case 1:
          text = i18n.t('Text in cart with 1 items')
          break;
        case 2:
          text = i18n.t('Text in cart with 2 items')
          break;
        default:
          text = i18n.t('Text in cart with more than 2 items')
          break;
      }
      return text;
    }
  },
  methods: {
    updateCartProducts () {
      if (this.isCartBusy) return
      const allProducts = [...this.productsInCart, ...this.removedProducts]
      const uniqueProducts = uniqBy(allProducts, this.createKey)
      this.productsToBeListed = this.productsOrder.flatMap(productKey => {
        return uniqueProducts.find(product => this.createKey(product) === productKey) || []
      })
      if (this.productsToBeListed.length !== uniqueProducts.length) {
        this.productsToBeListed = uniqueProducts
      }
    },
    async loadUpsellProducts () {
      this.upsellProducts = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.microCartUpsellId)
    },
    clearRevertableProducts () {
      this.removedProductsData = {}
      this.removedItems = {}
    },
    addRevertableRemovedProduct (product) {
      if (product.isGift) return
      Vue.set(this.removedProductsData, this.createKey(product), product)
      Vue.set(this.removedItems, this.createKey(product), { isRemovedFromCart: true })
    },
    removeRevertableRemovedProduct (product) {
      Vue.delete(this.removedProductsData, this.createKey(product))
      Vue.delete(this.removedItems, this.createKey(product))
    },
    createKey (product) {
      return createProductIdentifier(product)
    },
    closeMicrocartExtend () {
      this.$store.dispatch('ui/toggleMicrocart', false)
    },
    onEscapePress () {
      this.closeMicrocartExtend();
    },
    async removeProductHandler (product) {
      if (this.removedItems?.[this.createKey(product)]?.isRemovedFromCart) {
        product.server_item_id = null
        this.removeRevertableRemovedProduct(product)
        await this.$store.dispatch('cart/addItems', {
          productsToAdd: [product]
        });
        return
      }
      this.addRevertableRemovedProduct(product)
      this.$store.dispatch('cart/removeItem', { product });
    },
    startShopping () {
      this.$router.push(localizedRoute('/'));
      this.closeMicrocartExtend();
    },
    goToCheckout () {
      if (this.accepts || config.defaultStoreCode !== 'dk') {
        this.closeMicrocartExtend();
        this.$router.push(localizedRoute('/checkout'));
      } else {
        this.$store.dispatch('ui/toggleCheckoutSwitcher')
      }
    }
  },
  async mounted () {
    this.isMicrocartVisible = true;

    /**
     * Remove "broken" items on cart load.
     */
    await this.productsInCart.forEach(product => {
      if (!product || !product.qty) {
        this.$store.dispatch('cart/removeItem', { product });
      }
    });
    // await this.$store.dispatch('cart/synchronizeCart', { forceClientState: false })

    this.$bus.$emit('microcart-open', this.productsInCart)
    this.productsOrder = this.productsInCart.map(this.createKey)
    this.updateCartProducts()
    await this.loadUpsellProducts();
    this.$bus.$emit('slider-reinit')
  },
  watch: {
    productsInCart () {
      const newProducts = this.productsInCart
        .filter(productInMagentoCart => this.productsOrder.indexOf(this.createKey(productInMagentoCart)) === -1)
        .map(this.createKey)
      this.productsOrder = [...this.productsOrder, ...newProducts]
      this.updateCartProducts()
    },
    isMicrocartOpen (isOpened) {
      this.clearRevertableProducts()
      this.updateCartProducts()
      if (isOpened) {
        setTimeout(() => this.$bus.$emit('slider-reinit'), 1000);
      }
    },
    isCartBusy () {
      this.updateCartProducts()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.o-microcart-panel {
  --bar-height: 2.5rem;
  --bar-background: var(--c-white);
  --bar-font-weight: var(--font-weight--bold);
  --sidebar-content-padding: 0 var(--spacer-sm);
  @include for-desktop {
    --sidebar-circle-icon-top: calc(var(--spacer-xs) * 1.2);
    --sidebar-content-padding: var(--spacer-sm) var(--spacer-base);
    --sidebar-bottom-padding: var(--spacer-sm) var(--spacer-base);
  }
  ::v-deep .sf-sidebar__top {
    padding: var(--spacer-sm) var(--spacer-sm) 0 var(--spacer-sm);
  }
  ::v-deep .sf-sidebar__aside {
    max-width: 90%;
    @media only screen and (min-width: 420px) {
      max-width: 85%;
    }
    left: unset;
  }
  &__title {
    font-size: var(--font-size--lg);
    font-weight: var(--font-weight--medium);
    color: var(--c-secondary);
  }
  ::v-deep .sf-sidebar__content {
    --sidebar-content-padding: 0 var(--spacer-sm);
    height: auto;
    min-height: 155px;
  }
  ::v-deep .sf-sidebar__bottom {
    overflow: auto;
  }
  &__additional-pruchase-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--spacer-sm);
    margin-top: var(--spacer-sm);
    font-size: var(--font-size--sm);
    color: var(--c-text-secondary);
  }
  &__order-info-icon {
    display: inline;
    margin-right: var(--spacer-xs);
  }
  &__order-info-delivery-icon {
    ::v-deep {
      path {
        fill: transparent;
        stroke: var(--c-gray);
      }
    }
  }
  @include for-mobile {
    ::v-deep .sf-overlay{
      background-color: transparent
    }
  }
}
.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__total-items {
    margin: 0;
  }
  &__total-price {
    --price-font-size: var(--font-size--sm);
    --price-font-weight: var(--font-weight--semibold);
    margin: 0 0 var(--spacer-xs) 0;
  }
}
.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-base) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  &__heading {
    padding: 0 var(--spacer-base);
  }
  &__image {
    --image-width: 13.1875rem;
    margin: 0 0 var(--spacer-2xl) 0;
  }
  @include for-desktop {
    --heading-title-font-size: var(--font-size--lg);
  }
}
.free-shipping {
  padding-bottom: var(--spacer-xs);
  font-weight: var(--font-weight--bold);
}
.content-bottom {
  height: 100%;

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .form__checkbox {
    margin: var(--spacer-15) 0;
  }
}
</style>

<style lang="scss">
.o-microcart-panel {
  .sf-sidebar__aside {
    z-index: 9999999999;
  }
}
</style>
