<template>
  <div class="collected-product-list" :style="`--mini-thumbnail-width:${imageWidth}`">
    <transition-group name="fade" tag="div">
      <MCollectedProduct
        v-for="product in products"
        :key="createKey(product)"
        :image="getThumbnailForProductMini(product)"
        :image-width="imageWidth"
        :image-height="imageHeight"
        :title="product.name"
        :link="getProductUrl(product)"
        :regular-price="getProductPrice(product).regular"
        :special-price="getProductPrice(product).special"
        :qty="product.qty"
        :has-quantity-selector="hasQuantitySelector && !product.isGift"
        :disabled-updates="disabledUpdates"
        :is-pending="isCartBusy"
        class="collected-product"
        @click:remove="$emit('click:remove', product)"
        @click:add-to-cart="$emit('click:add-to-cart', product)"
        @input="changeQuantity(product, $event)"
        :is-removed-from-cart="checkIsRemovedFromCart(product)"
        @click:link="$emit('click:link')"
      >
        <template #configuration>
          <div class="collected-product__properties" v-if="!(product.type_id === 'bundle' && product.hide_inside_bundle == 1)">
            <SfProperty
              :class="{ 'is-disabled': isCartBusy }"
              v-for="(property, key) in getProductOptions(product)"
              :key="`${property.label}_${property.value}_${key}`"
            >
              <template #name>
                <span class="sf-property__name" :title="property.label" />
              </template>
              <template #value>
                <span class="sf-property__value" v-html="property.value" />
              </template>
            </SfProperty>
          </div>
        </template>
      </MCollectedProduct>
    </transition-group>
  </div>
</template>
<script>
import config from 'config';
import { mapGetters } from 'vuex';
import { onlineHelper } from '@vue-storefront/core/helpers';
import SfProperty from '@storefront-ui/vue/src/components/atoms/SfProperty/SfProperty.vue';

import { getProductPrice, getProductPriceFromTotals } from '~/theme/helpers';
import MCollectedProduct from '~/theme/components/molecules/m-collected-product';
import { createProductIdentifier } from '~/theme/helpers/cart'
import { prepareProductUrl } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'MCollectedProductsGroup',
  props: {
    products: {
      type: Array,
      required: true
    },
    hasQuantitySelector: {
      type: Boolean,
      default: true
    },
    disabledUpdates: {
      type: Boolean,
      default: false
    },
    additionalOptions: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    SfProperty,
    MCollectedProduct
  },
  computed: {
    ...mapGetters({
      isCartBusy: 'cart/getCartProcessingStatus',
      totals: 'cart/getTotals'

    }),
    imageWidth () {
      const width = config?.cart?.thumbnails?.width
      return width ? `${width}px` : ''
    },
    imageHeight () {
      const height = config?.cart?.thumbnails?.height
      return height ? `${height}px` : ''
    }
  },
  methods: {
    checkIsRemovedFromCart (product) {
      return this.additionalOptions?.[this.createKey(product)]?.isRemovedFromCart
    },
    createKey (product) {
      return createProductIdentifier(product)
    },
    getThumbnailForProductMini (product) {
      return product?.product_images?.mini || config.images.productPlaceholder;
    },
    getProductPrice (product) {
      if (product.isGift) {
        return getProductPrice(product)
      }
      return {
        regular: getProductPrice(product).regular,
        special: getProductPriceFromTotals(product).regular
      }
      // return onlineHelper.isOnline && product.totals && product.totals.options
      //   ? getProductPrice(product)
      //   : getProductPriceFromTotals(product);
    },
    getProductOptions (product) {
      return onlineHelper.isOnline && product.totals && product.totals.options
        ? product.totals.options.map(({ label, value }) => ({ // TODO: should be fetched already without price from backend
          label,
          value: value.replace(/ <span class="price".*span>/g, '')
        }))
        : product.options || {};
    },
    changeQuantity (product, newQuantity) {
      this.$store.dispatch('cart/updateQuantity', {
        product: product,
        qty: newQuantity
      });
    },
    getProductUrl (product) {
      return prepareProductUrl(product)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

::v-deep .sf-collected-product {
  &__image { // hack: override sfui collected product image
    img:not(.noscript) {
      position: unset;
      transform: none;
    }
  }
  .is-disabled {
    color: var(--c-text-disabled);
  }
  .collected-product__properties {
    --property-name-content: '';
    --property-name-margin: 0;
    --property-value-font-size: var(--font-size--xs);
    @include for-desktop {
      --property-value-font-size: calc(var(--font-size--xs) * 0.85);
    }
    margin: var(--spacer-xs) 0 0 0;
  }
}
</style>
