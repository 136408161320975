<template>
  <div class="sf-quantity-selector a-quantity-selector">
    <SfButton
      :disabled="disabled || disabledDecrease"
      class="sf-button--pure sf-quantity-selector__button"
      @click="onDecreaseButtonClick(qty)"
    >
      -
    </SfButton>
    <SfInput
      type="number"
      :value="qty"
      :min="minQty"
      :max="maxQty ? maxQty : undefined"
      v-bind="$attrs"
      :disabled="disabled"
      class="sf-quantity-selector__input"
      data-testid="sf-quantity-selector input"
      @input="$emit('input', parseInt($event, 10))"
    />
    <SfButton
      :disabled="disabled"
      class="sf-button--pure sf-quantity-selector__button"
      @click="onIncreaseButtonClick(qty)"
      :style="increaseStyle(qty)"
    >
      +
    </SfButton>
  </div>
</template>
<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';
export default {
  name: 'AQuantitySelector',
  components: {
    SfInput,
    SfButton
  },
  inheritAttrs: false,
  model: {
    prop: 'qty'
  },
  props: {
    qty: {
      type: [Number, String],
      default: 1
    },
    minQty: {
      type: [Number, String],
      default: 1
    },
    // eslint-disable-next-line vue/require-default-prop
    maxQty: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDecrease: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onIncreaseButtonClick (qty) {
      if (this.maxQty && qty >= this.maxQty) {
        this.$emit('max-qty-hit')
        return
      }
      this.$emit('input', parseInt(qty, 10) + 1)
    },
    onDecreaseButtonClick (qty) {
      let quantity = parseInt(qty, 10) - 1

      if (quantity > 0) {
        this.$emit('input', parseInt(qty, 10) - 1)
      } else {
        this.$emit('click:remove')
      }
    },
    increaseStyle (qty) {
      return this.maxQty && qty >= this.maxQty ? { color: 'var(--c-light)' } : null
    }
  },
  watch: {
    qty (qty, oldQty) {
      if (qty < this.minQty || isNaN(qty) || (this.maxQty && qty > this.maxQty)) {
        this.$emit('input', oldQty);
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfQuantitySelector.scss";
@import "~@storefront-ui/shared/styles/helpers/_breakpoints.scss";
.sf-quantity-selector__button {
  --button-background: transparent !important;
}
@media only screen and (max-width: $tablet-max) {
    .sf-quantity-selector__button:hover {
      text-shadow: none;
    }
  }
</style>
