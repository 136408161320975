<template>
  <div class="m-product-swiper">
    <swiper class="swiper flex" :options="swiperOption" :instance-name="sliderId">
      <swiper-slide
        v-for="(product, i) in carouselProducts"
        :key="i"
      >
        <OProductCard
          :key="product.sku"
          :title="product.title"
          :image="product.image"
          :hover-image="product.hover_image"
          wishlist-icon=""
          :regular-price="product.price.regular"
          :special-price="product.price.special"
          :final-price="getFinalPrice(product)"
          :max-rating="product.rating.max"
          :score-rating="product.rating.score"
          :link="product.link"
          :sku="product.sku"
          :parent-sku="product.parentSku"
          use-standard-images
          :type-id="product.typeId"
          :badges="product.badges"
          :badges-sort-order="product.badges_sort_order"
          :percentage-off="product.percentageOff"
          :gtm-custom-dimensions="product.gtmCustomDimensions"
          :gtm-item-list="gtmItemList"
        />
      </swiper-slide>
    </swiper>
    <div :id="sliderId + '-button-prev'" class="swiper-button-prev" slot="button-prev">
      <SfImage src="/assets/icons/prev.svg" alt="Previous" />
    </div>
    <div :id="sliderId + '-button-next'" class="swiper-button-next" slot="button-next">
      <SfImage src="/assets/icons/next.svg" alt="Next" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import { prepareListingProduct } from '~/theme/helpers';
import OProductCard from '~/theme/components/organisms/o-product-card';
import { gtmItemLists } from 'src/modules/google-tag-manager/types/gtmEvents.ts';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss'

SwiperCore.use([Navigation]);

export default {
  name: 'MProductCarousel',
  components: {
    OProductCard,
    Swiper,
    SwiperSlide,
    SfImage
  },
  data () {
    return {
      gtmItemList: gtmItemLists.ProductSliders,
      swiperOption: {
        slidesPerView: 'auto',
        grabCursor: true,
        observer: true,
        spaceBetween: 12,
        observeParents: true,
        threshold: 20,
        navigation: {
          nextEl: ('#' + this.sliderId + '-button-next'),
          prevEl: ('#' + this.sliderId + '-button-prev')
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    sliderId: {
      type: String,
      default: 'product-carousel'
    },
    products: {
      type: Array,
      default: () => []
    },
    perPageCustom: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    products: {
      handler (val) {
        if (isServer) return
        const listOfSkus = val.map(product => product.parentSku)
        this.getReviewsSummary({ skus: listOfSkus })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    sliderSettings () {
      if (this.id === 'upsell-slider') {
        return {
          type: 'carousel',
          rewind: false,
          perView: 2,
          slidePerPage: false,
          swipeThreshold: false,
          dragThreshold: false,
          startAt: 0,
          gap: 0,
          breakpoints: {
            1023: {
              perView: 2,
              peek: {
                before: 0,
                after: 0
              }
            }
          }
        }
      }

      return {
        rewindDuration: 400,
        rewind: true,
        type: 'carousel',
        perTouch: false,
        breakpoints: {
          1023: {
            touchRatio: 0.9,
            throttle: 25,
            animationTimingFunc: 'ease-in-out',
            animationDuration: 300,
            perView: 2,
            peek: {
              before: 0,
              after: 0
            }
          },
          1300: {
            perView: 3,
            peek: {
              before: 0,
              after: 100
            }
          }
        }
      }
    },
    isActive () {
      if (isServer) return false
      if (window.screen.width >= 1240) return this.hasEnoughSlidesForDesktop
      return this.hasEnoughSlidesForMobile
    },
    hasEnoughSlidesForDesktop () {
      return this.carouselProducts.length > 4
    },
    hasEnoughSlidesForMobile () {
      return this.carouselProducts.length > 2
    },
    carouselProducts () {
      return this.products.map(prepareListingProduct);
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      getReviewsSummary: 'getSummary'
    }),
    getFinalPrice (product) {
      return product?.price?.final || product?.final_price || product?.finalPrice
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#home, .product__bottom {
  .swiper-slider-wrap,
  .section-bestsellers .sf-section__content {
    @include for-mobile {
      padding: 0 15px;
    }
  }
  .sf-heading {
    @include for-mobile {
      padding-bottom: 15px;
    }
  }
}
.m-product-swiper {
  position: relative;
  .sf-product-card {
    padding: 0;
    height: 100%;
  }
  .swiper-slide {
    width: 301px;
    height: auto;
    @include for-mobile {
      width: 50%;
    }
  }
  .swiper-button-prev {
    position: var(--absolute);
    left: -40px;
    top: 50%;
    padding: var(--spacer-xs);
    margin-top: -20px;
    @include for-mobile {
      left: -4%;
      padding: 10px 0;
      z-index: 99;
    }
    cursor: var(--pointer);
  }
  .swiper-button-next {
    position: var(--absolute);
    right: -40px;
    top: 50%;
    padding: var(--spacer-xs);
    margin-top: -20px;
    @include for-mobile {
      right: -4%;
      padding: 10px 0;
      z-index: 99;
    }
    cursor: var(--pointer);
  }
  .swiper-button-disabled {
    display: none;
  }
}
.sf-carousel {
  --carousel-controls-display: flex;
  --carousel-controls-size: auto;
  --carousel-controls-width: 100%;
  --carousel-controls-top: 36%;
  --carousel-controls-left: -0.25%;
}
.sf-sidebar__aside .sf-sidebar__bottom {
  .m-product-swiper {
    .swiper-button-prev {
      left: 0;
      z-index: 2;
    }
    .swiper-button-next {
      right: 0;
      z-index: 2;
    }
    .swiper-slide {
      width: calc(50% - 6px);
    }
  }
}
</style>
